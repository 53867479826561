<template>
  <AdminView>
    <router-link class="back-link" :to="{ name: 'CoachingList' }">&lt; Retour</router-link>

    <div v-if="mode === 'edit'">
      <h1 class="title-1">Modifier le coaching</h1>
      <CoachingForm :coaching="coaching" mode="edit" />
    </div>

    <div v-if="mode === 'add'">
      <h1 class="title-1">Ajouter un coaching</h1>
      <CoachingForm mode="add" />
    </div>
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'
import CoachingForm from '@/components/Forms/CoachingForm'

export default {
  name: 'Coaching',
  components: {
    AdminView, CoachingForm
  },
  props: {
    mode: {
      type: String,
      default: 'edit'
    }
  },
  data () {
    return {
      coaching: {
        id: '1',
        name: 'Coaching 1',
        image: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 image.png',
        category: 1,
        subCategory: 1,
        video: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 consigne.mp4',
        videoExercise: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 déroulé.mp4',
        advice: 'https://lifebloomfrontdev.blob.core.windows.net/files/exo 1 conseil 1.m4a'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
