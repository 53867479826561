<template>
  <div class="form">
    <div class="column">
      <div class="row">
        <div class="column">
          <Input
            type="text"
            title="Nom"
            v-model="coaching.name"
          />

          <Select
            title="Catégorie"
            :options="categories"
            v-model="coaching.category"
          />

          <Select
            title="Sous-catégorie"
            :options="subCategories"
            v-model="coaching.subCategory"
          />
        </div>
        
        <div class="column">
          <InputFile
            type="image"
            title="Image"
            :preview="coaching.image"
            v-model="coaching.imageId"
            :onUploadStarts="() => isUploadingThumbnail = true"
            :onUploadEnds="() => isUploadingThumbnail = false"
          />
        </div>
      </div>

      <div class="row">
        <div class="column">
          <InputFile
            type="video"
            title="Vidéo consigne"
            :preview="coaching.video"
            v-model="coaching.videoId"
            :onUploadStarts="() => isUploadingVideo = true"
            :onUploadEnds="() => isUploadingVideo = false"
          />
        </div>
        <div class="column">
          <InputFile
            type="video"
            title="Vidéo réalisation exercice"
            :preview="coaching.videoExercise"
            v-model="coaching.videoExerciseId"
            :onUploadStarts="() => isUploadingVideoExercise = true"
            :onUploadEnds="() => isUploadingVideoExercise = false"
          />
        </div>
      </div>

      <div class="row">
        <InputFile
          type="audio"
          title="Conseil de réalisation"
          :preview="coaching.advice"
          v-model="coaching.adviceId"
          :onUploadStarts="() => isUploadingAdvice = true"
          :onUploadEnds="() => isUploadingAdvice = false"
        />
      </div>
    </div>

    <BottomBar v-if="mode === 'edit'">
      <div class="message">
        <p v-if="hasExerciseChanged">
          Modifications non sauvegardées
        </p>
      </div>

      <Button
        title="Sauvegarder"
        type="primary"
        :disabled="isUploading"
        @click="submit"
      />
      <Button
        title="Désactiver"
        type="warning"
      />
    </BottomBar>

    <BottomBar v-if="mode === 'add'">
      <div class="message">
        <p v-if="hasExerciseChanged">
          Modifications non sauvegardées
        </p>
      </div>

      <Button
        title="Sauvegarder"
        type="primary"
        :disabled="isUploading"
        @click="submit"
      />
    </BottomBar>
  </div>
</template>

<script>
import _ from 'lodash'
import BottomBar from '@/components/Layout/BottomBar'
import Input from '@/components/Forms/Fields/Input'
import InputFile from '@/components/Forms/Fields/InputFile'
import Select from '@/components/Forms/Fields/Select'
import Button from '@/components/Button'

export default {
  name: 'CoachingList',
  components: {
    Input, InputFile, Select, Button, BottomBar
  },
  props: {
    coaching: {
      default: function () {
        return {
          name: '',
          image: '',
          category: 1,
          subCategory: 1,
          video: '',
          videoExercise: '',
          advice: ''
        }
      }
    },
    mode: {
      type: String,
      default: 'edit'
    }
  },
  data () {
    return {
      originalCoaching: {},
      isUploadingThumbnail: false,
      isUploadingVideo: false,
      isUploadingVideoExercise: false,
      isUploadingAdvice: false,
      isSubmitting: false
    }
  },
  created () {
    this.originalCoaching = _.clone(this.coaching)
  },
  methods: {
    async submit () {

    }
  },
  computed: {
    categories () {
      const cats = ['Service & Appli', 'Oxilio & Autonomie', 'Maintien en Forme']
      return cats.map((cat, index) => ({
        value: index,
        name: cat
      }))
    },
    subCategories () {
      const cats = ['PrésaConnexion', 'Act quotidiennecoachautreeduc', 'Atteindre objectifsRangs &suivi durée', 'Suivre&dépasser objectifs', 'Commandes&usagesassis', 'Missesdebout&Asisse', 'March', 'Viedebout']
      return cats.map((cat, index) => ({
        value: index,
        name: cat
      }))
    },
    isUploading () {
      return (this.isUploadingThumbnail
        || this.isUploadingVideo
        || this.isUploadingVideoExercise
        || this.isUploadingAdvice
        || this.isSubmitting
      )
    },
    hasExerciseChanged () {
      return !_.isEqual(this.originalCoaching, this.coaching)
    }
  }
}
</script>

<style lang="scss" scoped>
.form{
  .row{
    div {
      flex: 1;
    }
  }

  .buttons-container{
    display: flex;
    justify-content: center;
    margin: 20px 0;

    div {
      flex: 0;
    }
  }

  .message{
    flex-grow: 1;
  }
}
</style>
